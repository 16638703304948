import {Injectable, Injector} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';


import {LoginService} from './login.service';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {environment} from '../environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private route: ActivatedRoute,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const loginService = this.injector.get(LoginService);
    if (request.url.indexOf('http') === -1 && request.url.indexOf(environment.service_url) === -1) {
      request = request.clone({
        setHeaders: {
          Authorization: `${loginService.access_token}`
        },
        url: environment.service_url + request.url
      });
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: `${loginService.access_token}`
        },
        url: request.url
      });
    }
    let handler = next.handle(request);
    next.handle(request).pipe(
      catchError(err => {
         return this.route.paramMap.pipe(tap((params: ParamMap) => {
            const next_route = params.get('name');
            if (err instanceof HttpErrorResponse && err.status == 401 && next_route !== 'login') {
              console.log('caught 401');
              let next = `${next_route}`;
              if (next_route !== null && next_route.indexOf('details') > -1) next += `:${params.get('id')}`;
              this.router.navigate(['login', {'next': next}]);
            }
          }
        ));
      }));
    return handler;
  }
}
