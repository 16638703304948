import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable, BehaviorSubject} from "rxjs";
import {LoadingService} from "./loading.service";
import {DataSource} from "@angular/cdk/collections";
import {switchMap, map} from "rxjs/operators";

export interface SearchObject {
  search?: string,
  page?: number,
  page_size?: number,
  ordering?: string,
  'fields!'?: string;
}

export interface Response {
  count: number;
  results: any[];
}

export abstract class BaseService {
  currentPage: number;
  count: number;
  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  filter: SearchObject;
  datasource: BaseDataSource;
  items: object[];
  searching: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public base_url: string, public http: HttpClient, public loadingService: LoadingService) {
    this.datasource = new BaseDataSource(this);
    this.filter = {page: 1};
  }

  get data(): any[] {
    return this.dataChange.value;
  }

  getList(search_object: SearchObject): Observable<Response> {
    let url = `/${this.base_url}`;
    return this.http.get<Response>(url,
      {
        params: Object.entries(search_object).reduce((params, [key, value]) => params.set(key, value), new HttpParams())
      }
    );
  }

  get(id: string | number) {
    return this.http.get<any>(`/${this.base_url}/${id}`);
  }

  put(id: string | number, item: object): any {
    return this.http.put<any>(`/${this.base_url}/${id}`, item).pipe(map(updated_item => {
      if (updated_item.hasOwnProperty('id')) {
        const copiedData = this.data.slice();
        let found = copiedData.filter(item => item.id === updated_item.id);
        if (found.length === 1) {
          let index = copiedData.indexOf(found[0]);
          copiedData.splice(index, 1, updated_item);
          this.dataChange.next(copiedData);
        }
      }
      return updated_item;
    }));
  }

  post(item: object): any {
    return this.http.post(`/${this.base_url}`, item).pipe(map(item => {
      const copiedData = this.data.slice();
      copiedData.push(item);
      this.dataChange.next(copiedData);
      return item;
    }));
  }

  delete(id: string | number) {
    return this.http.delete(`/${this.base_url}/${id}`).pipe(map(item => {
      const copiedData = this.data.slice();
      const filteredData = copiedData.filter(item => item.id !== id);
      this.dataChange.next(filteredData);
    }));
  }

  getItems(): Observable<any> {
    // this.loadingService.loading = true;

    return this.searching.pipe(switchMap(() => {
      return this.getList(this.filter).pipe(map(items => {
        this.currentPage = this.filter.page;
        this.count = items.count;
        this.dataChange.next(items.results);
        // this.loadingService.loading = false;
        return items;
      }));
    }));
  }

  clearItems() {
    this.dataChange.next([]);
  }

  getPage(event) {
    this.loadingService.show();
    this.filter.page = event.pageIndex + 1;
    this.filter.page_size = event.pageSize;
    this.getItems()
      .subscribe(() => this.loadingService.hide());
  }

  runSearch() {
    this.loadingService.show();
    this.filter.page = 1;
    this.searching.next(null);
  }

  sortTable(event) {
    // this.loadingService.loading = true;
    this.filter.page = 1;
    let direction = event.direction === 'desc' ? '-' : '';
    this.filter.ordering = event.direction ? `${direction}${event.active}` : '';
    this.getItems()
      .subscribe(() => this.loadingService.hide());
  }

  clearFilter(field) {
    delete this.filter[field];
  }
}

export class BaseDataSource extends DataSource<any> {
  constructor(private _database: BaseService) {
    super();
  }

  connect(): Observable<any[]> {
    return this._database.dataChange;
  }

  disconnect() {
  }

}
