import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {ProjectService} from "../services/project.service";
// import {ConfigService} from "../services/config.service";
import {MapService} from "../services/map.service";

import {loadModules} from 'esri-loader';
import {
  trigger,
  state,

  style,
  animate,
  transition,
  query,
} from '@angular/animations';
import {ArcGeoprocessorService} from '../services/arc-geoprocessor.service';
import {catchError, filter, finalize, first, flatMap, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {empty, iif, interval, of, timer} from 'rxjs';


@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.css'],

  animations: [
    trigger('spinInOut', [
      state('in', style({transform: 'rotate(0)', opacity: '1'})),
      transition(':enter', [
        style({transform: 'rotate(-180deg)', opacity: '0'}),
        animate('150ms ease')
      ]),
      transition(':leave', [
        animate('150ms ease', style({transform: 'rotate(180deg)', opacity: '0'}))
      ]),
    ]),
    trigger('preventInitialAnimation', [
      transition(':enter', [
        query(':enter', [], {optional: true})
      ]),
    ]),
  ],
})
export class MapViewComponent implements OnInit {
  MapImageLayer;
  esriConfig;
  result_id: string;
  gp_result_id: string;
  host = environment.ctuir_api_url;
  loading = true;
  gp_service_url: string;
  fgdb_download_url_base: string;
  result_map_service_url: string;
  fgdb_download_link: string;
  show_error = false;
  gp_service: ArcGeoprocessorService;

  // tabular_download_link = '<div id="tabular_download" style="box-shadow: none">\n' +
  //   '  <a href="{{ host }}download_results/{{ result_id }}/">\n' +
  //   '    <button mat-fab\n' +
  //   '            matTooltip="Click to download tabular data.">\n' +
  //   '      <mat-icon>file_copy</mat-icon>\n' +
  //   '    </button>\n' +
  //   '  </a>\n' +
  //   '</div>';
  // fgdb_download_link = '<div id="fgdb_download" style="box-shadow: none">\n' +
  //   '  <a *ngIf="fgdb_download_link" href="{{ fgdb_download_link }}">\n' +
  //   '    <button mat-fab\n' +
  //   '            matTooltip="Click to download spatial data.">\n' +
  //   '      <mat-icon>layers</mat-icon>\n' +
  //   '    </button>\n' +
  //   '  </a>\n' +
  //   '</div>';
  // map_spinner = '<mat-progress-spinner *ngIf="loading" mode="indeterminate" id="loading_spinner" style="top: 45%; left: 45%;"></mat-progress-spinner>';

  constructor(public mapService: MapService, public projectService: ProjectService, public route: ActivatedRoute,
              private el: ElementRef, public router: Router) {

    this.gp_service_url = `${environment.arcgis_host}/rest/services${environment.gp_service_url}`;
    let s = environment.gp_service_url.split('GPServer');
    this.result_map_service_url = `${environment.arcgis_host}/rest/services${s[0]}`;
    this.fgdb_download_url_base = `${environment.arcgis_host}/rest/directories/arcgisjobs${s[0].toLowerCase().slice(0, -1) + '_gpserver'}`
    this.gp_service = new ArcGeoprocessorService(this.gp_service_url);

    loadModules(['esri/layers/MapImageLayer', 'esri/config']).then(([_MapImageLayer, _esriConfig]) => {
      this.MapImageLayer = _MapImageLayer;
      this.esriConfig = _esriConfig;
    });

  }

  ngOnInit() {
    const vm = this;
    this.route.queryParamMap.pipe(
      first(),
      switchMap((params: ParamMap) => {
        this.result_id = params.get('input_data');
        this.gp_result_id = params.get('output_data');

        return this.mapService.init('mainMap').pipe(
          switchMap(([esri_map, view]) => {
            // cannot use view.ui.add for now switched to styling to position elements
            // view.ui.add('tabular_download', "top-right");
            // view.ui.add('fgdb_download', "top-right");
            // view.ui.add('loading_spinner')

            return iif(
              () => this.gp_result_id === null,
              this.gp_service.submit({result_id: this.result_id}),
              this.gp_service.checkStatus(this.gp_result_id).pipe(
                catchError(x => this.gp_service.submit({result_id: this.result_id}))
              )
            ).pipe(
              filter(x => x !== undefined),
              map(jobId => {
                this.fgdb_download_link = `${this.fgdb_download_url_base}/${jobId}/scratch/fgdb.zip`;
                let resultsLayer = new vm.MapImageLayer({
                  url: `${this.result_map_service_url}MapServer/jobs/${jobId}/`
                });
                resultsLayer.opacity = 0.5;
                esri_map.add(resultsLayer);
              }),
              catchError(() => {
                this.show_error = true;
                return of();
              }),
              finalize(() => this.loading = false)
            );
          })
        );
      })
    ).subscribe();

    this.gp_service.jobId.subscribe(jobId => {
      this.router.navigate(['/map'], {queryParams: {output_data: jobId}, queryParamsHandling: 'merge'});
    })
  }

}
