import { EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ScenarioSelectorComponent } from '../scenario-selector/scenario-selector.component';
import { AgeSelectorComponent } from '../age-selector/age-selector.component';
import { MediaPathwaysComponent } from '../media-pathways/media-pathways.component';
import { EnvironmentPathwaysComponent } from '../environment-pathways/environment-pathways.component';
import { ExistingDataComponent } from '../existing-data/existing-data.component';
import { HttpClient } from '@angular/common/http';
// import {ConfigService} from '../services/config.service';
import { LoadingService } from '../services/loading.service';
import { Router } from '@angular/router';
import { filter, finalize, flatMap, map, take } from 'rxjs/operators';
import { interval } from 'rxjs';
import { environment } from '../../environments/environment';
var SelectorStepperComponent = /** @class */ (function () {
    function SelectorStepperComponent(_formBuilder, http, loadingService, router) {
        this._formBuilder = _formBuilder;
        this.http = http;
        this.loadingService = loadingService;
        this.router = router;
        //
        this.isLinear = false;
        this.calculating = false;
    }
    Object.defineProperty(SelectorStepperComponent.prototype, "scenarioForm", {
        get: function () {
            return this.scenarioSelectorComponent ? this.scenarioSelectorComponent.scenarioForm : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectorStepperComponent.prototype, "ageForm", {
        get: function () {
            return this.ageSelectorComponent ? this.ageSelectorComponent.ageForm : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectorStepperComponent.prototype, "mediaForm", {
        get: function () {
            return this.mediaPathwaysComponent ? this.mediaPathwaysComponent.mediaForm : null;
        },
        enumerable: true,
        configurable: true
    });
    SelectorStepperComponent.prototype.submitSelections = function () {
        var _this = this;
        this.calculating = true;
        // this.loadingService.show()
        this.http.post(environment.ctuir_api_url + "chem/" + this.scenarioForm.value.Scenario + "/" + this.ageForm.value.Age + "/", this.mediaForm.value)
            .pipe(flatMap(function (response) { return interval(5000).pipe(flatMap(function () { return _this.http.get(environment.ctuir_api_url + "gettaskstatus/" + response.task + "/"); }), //pass back two query parameters. response.chemicaltask
        filter(function (x) { return x === 'SUCCESS'; }), take(1), map(function () { return _this.router.navigate(['/map'], { queryParams: { input_data: response.final_result_id } }); })); }), finalize(function () { return _this.calculating = false; })).subscribe();
    };
    ;
    return SelectorStepperComponent;
}());
export { SelectorStepperComponent };
