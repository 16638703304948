var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from './loading.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loading.service";
// import {load} from '@angular/core/src/render3/instructions';
var ProjectService = /** @class */ (function (_super) {
    __extends(ProjectService, _super);
    function ProjectService(http, loadingService) {
        return _super.call(this, 'http://127.0.0.1:8000/api/', http, loadingService) || this;
    }
    ProjectService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectService_Factory() { return new ProjectService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoadingService)); }, token: ProjectService, providedIn: "root" });
    return ProjectService;
}(BaseService));
export { ProjectService };
