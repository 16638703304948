import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ScenarioService} from "../services/scenario.service";
import {Observable} from "rxjs/index";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/internal/operators";


@Component({
  selector: 'app-scenario-selector',
  templateUrl: './scenario-selector.component.html',
  styleUrls: ['./scenario-selector.component.css']
})
export class ScenarioSelectorComponent implements OnInit {

  scenarioForm: FormGroup;
  scenarios: any[];

  scenario: Observable<any>;
  scenarioService: ScenarioService;

  constructor(private _formBuilder: FormBuilder, private http: HttpClient) {
  this.scenarioService = new ScenarioService( 'scenario', this.http);
  }

  ngOnInit() {
    // this.scenario = this.scenarioService.getChoices().pipe(map(results => {this.scenarioForm.patchValue(results)}))
    this.scenarioForm = this._formBuilder.group({
      Scenario: ['', Validators.required]
    });
    this.scenarios = [
      //{value: 'TI-707 Rev 5 All Pathways', viewValue: 'TI-707 Rev 5 All Pathways'},
      {value: 'CTUIR All Pathways', viewValue: 'CTUIR All Pathways'},
      {value: 'Seasonal Cultural User', viewValue: 'Seasonal Cultural'},
      {value: 'Day Gatherer', viewValue: 'Day Gatherer'},
      {value: 'Day Hunter', viewValue: 'Day Hunter'},
      {value: 'Day Fisherman', viewValue: 'Day Fisherman'},
    ];
  }


}
