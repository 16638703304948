<mat-card>

  <form [formGroup]="scenarioForm">

    <mat-form-field>
      <mat-select formControlName="Scenario">
        <mat-option *ngFor="let option of scenarios" [value]="option.value">{{
          option.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-card-actions>
      <button mat-raised-button matStepperNext class="nav-btn pull-right">Next</button>
    </mat-card-actions>

  </form>

</mat-card>
