<mat-card>

  <form [formGroup]="ageForm">

    <mat-form-field>
      <mat-select formControlName="Age">
        <mat-option (click)="openDialog()" *ngFor="let option of ageData" [value]="option.value">{{
          option.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-card-actions>
      <button mat-raised-button matStepperNext class="nav-btn pull-right">Next</button>
    </mat-card-actions>

  </form>

</mat-card>
