export const environment = {
  production: true,
  ctuir_api_url: 'https://${BACKEND_HOST}/api/',
  arcgis_host: '${ARCGIS_HOST}',
  gp_service_url: '${GP_SERVICE_URL}',
  service_url: 'https://${BACKEND_HOST}/api',
  google_client_id: 'none.apps.googleusercontent.com',
  local_client_id: 'WxP6AzUx1jqD5wLmipGQqgGJOd9PJ0fTcZ3uh0wW',
  oauth2_redirect_uri: 'https://${BACKEND_HOST}/oauthcallback',
  local_token_endpoint: 'https://${BACKEND_HOST}/api/auth/convert-token'
};
