var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { DataSource } from "@angular/cdk/collections";
import { switchMap, map } from "rxjs/operators";
var BaseService = /** @class */ (function () {
    function BaseService(base_url, http, loadingService) {
        this.base_url = base_url;
        this.http = http;
        this.loadingService = loadingService;
        this.dataChange = new BehaviorSubject([]);
        this.searching = new BehaviorSubject(null);
        this.datasource = new BaseDataSource(this);
        this.filter = { page: 1 };
    }
    Object.defineProperty(BaseService.prototype, "data", {
        get: function () {
            return this.dataChange.value;
        },
        enumerable: true,
        configurable: true
    });
    BaseService.prototype.getList = function (search_object) {
        var url = "/" + this.base_url;
        return this.http.get(url, {
            params: Object.entries(search_object).reduce(function (params, _a) {
                var key = _a[0], value = _a[1];
                return params.set(key, value);
            }, new HttpParams())
        });
    };
    BaseService.prototype.get = function (id) {
        return this.http.get("/" + this.base_url + "/" + id);
    };
    BaseService.prototype.put = function (id, item) {
        var _this = this;
        return this.http.put("/" + this.base_url + "/" + id, item).pipe(map(function (updated_item) {
            if (updated_item.hasOwnProperty('id')) {
                var copiedData = _this.data.slice();
                var found = copiedData.filter(function (item) { return item.id === updated_item.id; });
                if (found.length === 1) {
                    var index = copiedData.indexOf(found[0]);
                    copiedData.splice(index, 1, updated_item);
                    _this.dataChange.next(copiedData);
                }
            }
            return updated_item;
        }));
    };
    BaseService.prototype.post = function (item) {
        var _this = this;
        return this.http.post("/" + this.base_url, item).pipe(map(function (item) {
            var copiedData = _this.data.slice();
            copiedData.push(item);
            _this.dataChange.next(copiedData);
            return item;
        }));
    };
    BaseService.prototype.delete = function (id) {
        var _this = this;
        return this.http.delete("/" + this.base_url + "/" + id).pipe(map(function (item) {
            var copiedData = _this.data.slice();
            var filteredData = copiedData.filter(function (item) { return item.id !== id; });
            _this.dataChange.next(filteredData);
        }));
    };
    BaseService.prototype.getItems = function () {
        // this.loadingService.loading = true;
        var _this = this;
        return this.searching.pipe(switchMap(function () {
            return _this.getList(_this.filter).pipe(map(function (items) {
                _this.currentPage = _this.filter.page;
                _this.count = items.count;
                _this.dataChange.next(items.results);
                // this.loadingService.loading = false;
                return items;
            }));
        }));
    };
    BaseService.prototype.clearItems = function () {
        this.dataChange.next([]);
    };
    BaseService.prototype.getPage = function (event) {
        var _this = this;
        this.loadingService.show();
        this.filter.page = event.pageIndex + 1;
        this.filter.page_size = event.pageSize;
        this.getItems()
            .subscribe(function () { return _this.loadingService.hide(); });
    };
    BaseService.prototype.runSearch = function () {
        this.loadingService.show();
        this.filter.page = 1;
        this.searching.next(null);
    };
    BaseService.prototype.sortTable = function (event) {
        var _this = this;
        // this.loadingService.loading = true;
        this.filter.page = 1;
        var direction = event.direction === 'desc' ? '-' : '';
        this.filter.ordering = event.direction ? "" + direction + event.active : '';
        this.getItems()
            .subscribe(function () { return _this.loadingService.hide(); });
    };
    BaseService.prototype.clearFilter = function (field) {
        delete this.filter[field];
    };
    return BaseService;
}());
export { BaseService };
var BaseDataSource = /** @class */ (function (_super) {
    __extends(BaseDataSource, _super);
    function BaseDataSource(_database) {
        var _this = _super.call(this) || this;
        _this._database = _database;
        return _this;
    }
    BaseDataSource.prototype.connect = function () {
        return this._database.dataChange;
    };
    BaseDataSource.prototype.disconnect = function () {
    };
    return BaseDataSource;
}(DataSource));
export { BaseDataSource };
