import { ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectService } from "../services/project.service";
// import {ConfigService} from "../services/config.service";
import { MapService } from "../services/map.service";
import { loadModules } from 'esri-loader';
import { ArcGeoprocessorService } from '../services/arc-geoprocessor.service';
import { catchError, filter, finalize, first, map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { iif, of } from 'rxjs';
var MapViewComponent = /** @class */ (function () {
    // tabular_download_link = '<div id="tabular_download" style="box-shadow: none">\n' +
    //   '  <a href="{{ host }}download_results/{{ result_id }}/">\n' +
    //   '    <button mat-fab\n' +
    //   '            matTooltip="Click to download tabular data.">\n' +
    //   '      <mat-icon>file_copy</mat-icon>\n' +
    //   '    </button>\n' +
    //   '  </a>\n' +
    //   '</div>';
    // fgdb_download_link = '<div id="fgdb_download" style="box-shadow: none">\n' +
    //   '  <a *ngIf="fgdb_download_link" href="{{ fgdb_download_link }}">\n' +
    //   '    <button mat-fab\n' +
    //   '            matTooltip="Click to download spatial data.">\n' +
    //   '      <mat-icon>layers</mat-icon>\n' +
    //   '    </button>\n' +
    //   '  </a>\n' +
    //   '</div>';
    // map_spinner = '<mat-progress-spinner *ngIf="loading" mode="indeterminate" id="loading_spinner" style="top: 45%; left: 45%;"></mat-progress-spinner>';
    function MapViewComponent(mapService, projectService, route, el, router) {
        var _this = this;
        this.mapService = mapService;
        this.projectService = projectService;
        this.route = route;
        this.el = el;
        this.router = router;
        this.host = environment.ctuir_api_url;
        this.loading = true;
        this.show_error = false;
        this.gp_service_url = environment.arcgis_host + "/rest/services" + environment.gp_service_url;
        var s = environment.gp_service_url.split('GPServer');
        this.result_map_service_url = environment.arcgis_host + "/rest/services" + s[0];
        this.fgdb_download_url_base = environment.arcgis_host + "/rest/directories/arcgisjobs" + (s[0].toLowerCase().slice(0, -1) + '_gpserver');
        this.gp_service = new ArcGeoprocessorService(this.gp_service_url);
        loadModules(['esri/layers/MapImageLayer', 'esri/config']).then(function (_a) {
            var _MapImageLayer = _a[0], _esriConfig = _a[1];
            _this.MapImageLayer = _MapImageLayer;
            _this.esriConfig = _esriConfig;
        });
    }
    MapViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        var vm = this;
        this.route.queryParamMap.pipe(first(), switchMap(function (params) {
            _this.result_id = params.get('input_data');
            _this.gp_result_id = params.get('output_data');
            return _this.mapService.init('mainMap').pipe(switchMap(function (_a) {
                // cannot use view.ui.add for now switched to styling to position elements
                // view.ui.add('tabular_download', "top-right");
                // view.ui.add('fgdb_download', "top-right");
                // view.ui.add('loading_spinner')
                var esri_map = _a[0], view = _a[1];
                return iif(function () { return _this.gp_result_id === null; }, _this.gp_service.submit({ result_id: _this.result_id }), _this.gp_service.checkStatus(_this.gp_result_id).pipe(catchError(function (x) { return _this.gp_service.submit({ result_id: _this.result_id }); }))).pipe(filter(function (x) { return x !== undefined; }), map(function (jobId) {
                    _this.fgdb_download_link = _this.fgdb_download_url_base + "/" + jobId + "/scratch/fgdb.zip";
                    var resultsLayer = new vm.MapImageLayer({
                        url: _this.result_map_service_url + "MapServer/jobs/" + jobId + "/"
                    });
                    resultsLayer.opacity = 0.5;
                    esri_map.add(resultsLayer);
                }), catchError(function () {
                    _this.show_error = true;
                    return of();
                }), finalize(function () { return _this.loading = false; }));
            }));
        })).subscribe();
        this.gp_service.jobId.subscribe(function (jobId) {
            _this.router.navigate(['/map'], { queryParams: { output_data: jobId }, queryParamsHandling: 'merge' });
        });
    };
    return MapViewComponent;
}());
export { MapViewComponent };
