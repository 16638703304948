<h1 mat-dialog-title>{{data.name}}</h1>
<div mat-dialog-content>
  <p>{{data.message}}</p>
  <!-- <mat-form-field>
    <input matInput [(ngModel)]="data.returned">
  </mat-form-field> -->
</div>
<div mat-dialog-actions>
  <!-- button mat-button (click)="onNoClick()">No Thanks</button -->
  <button mat-button [mat-dialog-close]="data.returned" cdkFocusInitial>Ok</button>
</div>
