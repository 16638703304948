import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, switchMap} from 'rxjs/operators';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
  ActivatedRoute
} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';


@Injectable()
export class LoginService implements CanActivate {
  static google_url = 'https://accounts.google.com/o/oauth2/v2/auth';
  static scope = 'email';
  static access_type = 'online';
  private redirect_uri: string;
  static include_granted_scope = 'true';
  private google_client_id: string;
  static response_type = 'token';
  private local_client_id: string;
  public display_name: string;
  access_token;
  token_expires: Date;

  constructor(private http: HttpClient, private router: Router) {
    this.google_client_id = environment.google_client_id;
    this.local_client_id = environment.local_client_id;
    this.redirect_uri = environment.oauth2_redirect_uri;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(resolve => {
      this.loadToken();
      this.isTokenValid().pipe(
        switchMap(() => {
            return this.http.get<any>(environment.service_url + '/current_user/').pipe(
              map(response => {
                this.display_name = response.name;
                resolve(true);
              }));
          }
        ),
        catchError(() => this.router.navigate(['login']))
      ).subscribe();

    });


  }

  sendToLogin(state?: string) {
    let params: object = {
      scope: LoginService.scope, access_type: LoginService.access_type,
      redirect_uri: this.redirect_uri, include_granted_scope: LoginService.include_granted_scope,
      client_id: this.google_client_id, response_type: LoginService.response_type,
      state: state
    };

    let url_params: string[] = Object.keys(params).map(key => key + '=' + params[key]);

    window.location.href = LoginService.google_url + '?' + url_params.join('&');
  }

  login(username: string, password: string) {
    let data = {
      username: username,
      password: password
    };
    return this.http.post(`/rest-auth/login/`, data)
      .pipe(map(response => this.setAccessToken(`Token ${response['key']}`, 3600)));
  }

  convertToken(access_token: string, expires_in: string) {
    return this.http.post(environment.local_token_endpoint, '', {
      params: new HttpParams().set('grant_type', 'convert_token')
        .set('client_id', this.local_client_id)
        .set('backend', 'google-oauth2')
        .set('token', access_token)
    }).pipe(map(response =>
      this.setAccessToken(`Bearer ${response['access_token']}`, response['expires_in'])));
  }

  setAccessToken(access_token: string, expires_in: number) {
    localStorage.setItem('access_token', access_token);
    this.access_token = access_token;
    let now = new Date().getTime();
    this.token_expires = new Date(now + (expires_in * 1000));
    localStorage.setItem('token_expires', this.token_expires.toISOString());
  }

  loadToken() {
    let expiration_date = String(localStorage.getItem('token_expires'));
    this.token_expires = expiration_date !== 'null' ? new Date(expiration_date) : new Date();
    this.access_token = localStorage.getItem('access_token');
  }

  isTokenValid() {
    return new Observable(obs => {
      let now = new Date();
      let isValid = (this.token_expires > now);
      if (isValid) {
        obs.next();
        obs.complete();
      } else obs.error();
    });
  }

  clearToken() {
    delete this.token_expires;
    delete this.access_token;
    this.display_name = '';
    localStorage.clear();
  }
}
