import { Component, OnInit } from '@angular/core';
import {LoginService} from './login.service';
import {Router} from '@angular/router';
import { VERSION } from '../environments/version';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  title = 'app';
  version = VERSION;
  constructor(public loginService: LoginService, public router: Router) { }
  ngOnInit() {
    this.loginService.loadToken();
  }

    logout() {
    this.loginService.clearToken();
    this.router.navigate(['login']);
  }
}
