<div id="mainMap" style="flex: 1">

  <div id="tabular_download" style="position: absolute; right: 15px; padding-top: 15px;">
    <a href="{{ host }}download_results/{{ result_id }}/">
      <button mat-fab
              matTooltip="Click to download tabular data.">
        <mat-icon>file_copy</mat-icon>
      </button>
    </a>
  </div>
  <div id="fgdb_download" style="position: absolute; right: 15px; margin-top: 86px;">
    <a *ngIf="fgdb_download_link && !show_error" href="{{ fgdb_download_link }}">
      <button mat-fab
              matTooltip="Click to download spatial data.">
        <mat-icon>layers</mat-icon>
      </button>
    </a>
  </div>
  <mat-progress-spinner *ngIf="loading" mode="indeterminate" id="loading_spinner"
                        style="position: absolute; top: 50%; left: 45%;"></mat-progress-spinner>
  <div *ngIf="show_error" style="position: absolute; top: 50%; left: 45%;">
    <h2>No layers were created.</h2>
  </div>
</div>

<div class="basemap-speed-dial">
  <!-- Fab
  <button mat-fab
          [@preventInitialAnimation]
          [satPopoverAnchorFor]="dial"
          (click)="dial.toggle()">
    <mat-icon [@spinInOut]="'in'" *ngIf="dial.isOpen()">close</mat-icon>
    <mat-icon [@spinInOut]="'in'" *ngIf="!dial.isOpen()">map</mat-icon>
  </button>
  <!-- Actions -->
  <!-- <sat-popover #dial verticalAlign="above">
    <div class="dial">
      <ng-container *ngFor="let q of mapService.basemaps | async">
        <button mat-mini-fab
                color="accent"
                [satPopoverAnchorFor]="tooltip"
                (mouseenter)="tooltip.open()"
                (mouseleave)="tooltip.close()"
                (click)="mapService.switchBasemap(q); dial.close()"
                [ngStyle]="{'background': 'url('+q.thumbnailUrl+')'}">
        </button>
        <sat-popover #tooltip horizontalAlign="before">
          <div class="tooltip mat-body-1">
            {{q.title}}
          </div>
        </sat-popover>
      </ng-container>
    </div>
  </sat-popover> -->
</div>




