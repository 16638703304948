/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/toolbar";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "@angular/material/menu";
import * as i12 from "@angular/cdk/overlay";
import * as i13 from "@angular/cdk/bidi";
import * as i14 from "../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i15 from "./app.component";
import * as i16 from "./login.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "mat-toolbar", [["class", "header mat-toolbar"], ["style", "justify-content: space-between"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], null, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "div", [["routerLink", ""], ["style", "cursor: pointer;display: flex; align-items: center;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(5, null, ["CTUIR Risk Calculator Web Application v", ""])), (_l()(), i1.ɵeld(6, 0, null, 0, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "mat-menu-trigger"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(9, 1196032, null, 0, i11.MatMenuTrigger, [i12.Overlay, i1.ElementRef, i1.ViewContainerRef, i11.MAT_MENU_SCROLL_STRATEGY, [2, i11.MatMenu], [8, null], [2, i13.Directionality], i9.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵted(10, 0, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 9, "mat-menu", [], null, null, null, i14.View__MatMenu_0, i14.RenderType__MatMenu)), i1.ɵdid(12, 1294336, [["menu", 4]], 3, i11._MatMenu, [i1.ElementRef, i1.NgZone, i11.MAT_MENU_DEFAULT_OPTIONS], null, null), i1.ɵqud(603979776, 2, { _allItems: 1 }), i1.ɵqud(603979776, 3, { items: 1 }), i1.ɵqud(603979776, 4, { lazyContent: 0 }), i1.ɵprd(2048, null, i11.MatMenu, null, [i11._MatMenu]), i1.ɵprd(2048, null, i11.MAT_MENU_PANEL, null, [i11.MatMenu]), (_l()(), i1.ɵeld(18, 0, null, 0, 2, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 19)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.logout() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i14.View_MatMenuItem_0, i14.RenderType_MatMenuItem)), i1.ɵdid(19, 180224, [[2, 4], [3, 4]], 0, i11.MatMenuItem, [i1.ElementRef, i5.DOCUMENT, i9.FocusMonitor, [2, i11.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵted(-1, 0, ["Logout"])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [["class", "main-content"], ["style", "display: flex; flex: 1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(23, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 4, 0, currVal_2); var currVal_7 = i1.ɵnov(_v, 12); _ck(_v, 9, 0, currVal_7); _ck(_v, 12, 0); _ck(_v, 23, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.version.version; _ck(_v, 5, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 8).disabled || null); var currVal_5 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); var currVal_6 = (i1.ɵnov(_v, 9).menuOpen || null); _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.loginService.display_name; _ck(_v, 10, 0, currVal_8); var currVal_9 = i1.ɵnov(_v, 19).role; var currVal_10 = i1.ɵnov(_v, 19)._highlighted; var currVal_11 = i1.ɵnov(_v, 19)._triggersSubmenu; var currVal_12 = i1.ɵnov(_v, 19)._getTabIndex(); var currVal_13 = i1.ɵnov(_v, 19).disabled.toString(); var currVal_14 = (i1.ɵnov(_v, 19).disabled || null); _ck(_v, 18, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i15.AppComponent, [i16.LoginService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i15.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
