import { loadModules } from 'esri-loader';
import { BehaviorSubject, ReplaySubject } from "rxjs";
import * as i0 from "@angular/core";
var MapService = /** @class */ (function () {
    function MapService() {
        this.mapObs = new ReplaySubject();
        this.basemaps = new BehaviorSubject([]);
    }
    MapService.prototype.init = function (elementId) {
        var map, view, vm = this;
        var usaTopo = {
            baseMapLayers: [{ url: 'https://services.arcgisonline.com/ArcGIS/rest/services/USA_Topo_Maps/MapServer' }],
            thumbnailUrl: 'http://www.arcgis.com/sharing/rest/content/items/931d892ac7a843d7ba29d085e0433465/info/thumbnail/usa_topo.jpg',
            title: 'USA Topo'
        };
        // vm.mapObs = new ReplaySubject<any>();
        loadModules(['esri/Map', 'esri/views/MapView', 'esri/widgets/LayerList', 'esri/widgets/Legend'])
            .then(function (_a) {
            // vm.basemaps.next(
            //   [basemaps.streets,
            //     basemaps.satellite,
            //     basemaps.terrain,
            //     basemaps.topo,
            //     basemaps.oceans,
            //     basemaps.gray,
            //     usaTopo]);
            var Map = _a[0], MapView = _a[1], LayerList = _a[2], Legend = _a[3];
            vm.map = new Map({
                // center: [-119.4890, 46.5507],
                // zoom: 11,
                basemap: 'topo',
            });
            view = new MapView({
                map: vm.map,
                container: elementId,
                center: [-119.482, 46.645],
                zoom: 13,
            });
            var legend = new Legend({
                view: view
            });
            view.ui.add(legend, "bottom-right");
            var layerList = new LayerList({
                view: view,
                listItemCreatedFunction: function (event) {
                    // The event object contains properties of the
                    // layer in the LayerList widget.
                    var item = event.item;
                    if ((item.title === "RiskCalcGPService") || (item.title === "500ft² Grid")) {
                        item.visible = true;
                    }
                    else {
                        item.visible = false;
                    }
                }
            });
            view.ui.add(layerList, {
                position: "top-left"
            });
            view.when(function () {
                vm.mapObs.next([vm.map, view]);
                vm.mapObs.complete();
            });
        });
        return this.mapObs;
    };
    MapService.prototype.addLayer = function (layer) {
        var vm = this;
        this.mapObs.subscribe(function () {
            vm.map.add(layer);
        });
    };
    MapService.prototype.center = function (geometry) {
        this.mapObs.subscribe(function (map) {
            setTimeout(function () {
                if (geometry.type === 'point')
                    map.centerAt(geometry);
                else if (geometry.type === 'polygon')
                    map.setExtent(geometry.getExtent(), true);
                // map.setZoom(map.getZoom() - 1);
            }, 500);
        });
    };
    MapService.prototype.zoomIn = function () {
        this.mapObs.subscribe(function (map) { return map.setZoom(map.getZoom() + 1); });
    };
    MapService.prototype.zoomOut = function () {
        this.mapObs.subscribe(function (map) { return map.setZoom(map.getZoom() - 1); });
    };
    MapService.prototype.addGraphic = function (graphic) {
        this.mapObs.subscribe(function (map) { return map.graphics.add(graphic); });
    };
    MapService.prototype.redrawGraphics = function () {
        this.mapObs.subscribe(function (map) { return map.graphics.redraw(); });
    };
    MapService.prototype.clearGraphics = function () {
        this.mapObs.subscribe(function (map) { return map.graphics.clear(); });
    };
    MapService.prototype.switchBasemap = function (basemap) {
        this.mapObs.subscribe(function (map) { return map.setBasemap(basemap); });
    };
    MapService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapService_Factory() { return new MapService(); }, token: MapService, providedIn: "root" });
    return MapService;
}());
export { MapService };
