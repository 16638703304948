import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {HomePageComponent} from './home-page/home-page.component';
import {ScenarioSelectorComponent} from './scenario-selector/scenario-selector.component';
import {AgeSelectorComponent} from './age-selector/age-selector.component';
import {MediaPathwaysComponent} from './media-pathways/media-pathways.component';
import {EnvironmentPathwaysComponent} from './environment-pathways/environment-pathways.component';
import {SelectorStepperComponent} from './selector-stepper/selector-stepper.component';
import {MapViewComponent} from './map-view/map-view.component';
import {UrfTableComponent} from './urf-table/urf-table.component';
import {ResultsComponent} from './results/results.component';
import {LoginComponent} from './login/login.component';
import {LoginService} from './login.service';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: '', component: HomePageComponent, canActivate: [LoginService]},
  {path: 'select', component: SelectorStepperComponent, canActivate: [LoginService]},
  {path: 'map', component: MapViewComponent, canActivate: [LoginService]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
