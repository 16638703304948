import { loadModules } from 'esri-loader';
import { Observable, ReplaySubject, Subject, timer } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { switchMap } from 'rxjs/internal/operators/switchMap';
// @Injectable({
//   providedIn: 'root'
// })
var ArcGeoprocessorService = /** @class */ (function () {
    function ArcGeoprocessorService(url) {
        this.ready = new ReplaySubject();
        this.jobId = new Subject();
        var vm = this;
        vm.url = url;
        loadModules(['esri/tasks/Geoprocessor', 'esri/config']).then(function (_a) {
            var Geoprocessor = _a[0], _esriConfig = _a[1];
            vm.gp = new Geoprocessor("" + url);
            vm.esriConfig = _esriConfig;
            vm.ready.next();
        });
    }
    ArcGeoprocessorService.prototype.initRequestInterceptor = function () {
        var vm = this;
        vm.esriConfig.request.interceptors.push({
            urls: vm.url,
            after: function (response) {
                vm.jobId.next(response.data.jobId);
                vm.jobId.complete();
            }
        });
    };
    ArcGeoprocessorService.prototype.submit = function (params) {
        var vm = this;
        return new Observable(function (obs) {
            vm.ready.subscribe(function () {
                vm.initRequestInterceptor();
                vm.gp.submitJob(params).then(function (e) {
                    if (e.jobStatus === 'job-failed') {
                        // todo: raise error
                        obs.error();
                    }
                    else {
                        // vm.gp.getResultData(e.jobId, 'heat_map', function (result) {
                        obs.next(e.jobId);
                        obs.complete();
                        // });
                    }
                });
            });
        });
    };
    ArcGeoprocessorService.prototype.checkStatus = function (jobId) {
        var vm = this;
        var failed = ["job-failed", "job-cancelled", 'job-cancelling', 'job-deleted', 'job-deleting', 'job-timed-out'];
        var execute = ["job-executing", "job-failed", "job-new", "job-submitted", "job-waiting"];
        return new Observable(function (obs) {
            var sub = timer(0, 5000).pipe(switchMap(function () { return vm.ready; }), tap(function () {
                vm.gp.checkJobStatus(jobId).then(function (e) {
                    if (failed.indexOf(e.jobStatus) > -1) {
                        obs.error();
                        sub.unsubscribe();
                    }
                    else if (e.jobStatus === 'job-succeeded') {
                        obs.next(jobId);
                        obs.complete();
                        sub.unsubscribe();
                    }
                }).catch(function (error) {
                    obs.error();
                    sub.unsubscribe();
                });
            })).subscribe();
        });
    };
    return ArcGeoprocessorService;
}());
export { ArcGeoprocessorService };
