import {Component, OnInit} from '@angular/core';
import {LoginService} from '../login.service';
import {isUndefined} from 'util';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  login_error: string;
  username: string;
  password: string;
  next_state: string;
  constructor(private loginService: LoginService, public route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      tap((params: ParamMap) => this.next_state = params.get('next)'))
    )
  }

  login() {
    let next: string;
    if (!isUndefined(this.next_state)) next = this.next_state;
    else next = 'root';
    this.loginService.sendToLogin(next);
  }

  loginWithCredentials() {
    this.login_error = '';
    this.loginService.login(this.username, this.password).subscribe(() => {
      if (this.next_state) {
        this.router.navigate([this.next_state]);
      } else {
        this.router.navigate(['']);
      }
    }, error => {
      let error_object = JSON.parse(error.error);
      this.login_error = error_object.non_field_errors[0];
    });
  }
}
