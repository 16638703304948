<mat-card>

  <form [formGroup]="mediaForm">

    <mat-form-field
      (click)="openDialog('Aquifer Water')">
      <mat-placeholder>Aquifer Water</mat-placeholder>
      <mat-select formControlName="Aquifer" multiple>
        <app-select-check-all [model]="mediaForm.get('Aquifer')" [values]="aquiferShortList">
        </app-select-check-all>
        <mat-option *ngFor="let option of aquiferWater | async" [value]="option.key">
          {{option.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      (click)="openDialog('Irrigated Surface Soil')">
      <mat-placeholder>Irrigated Surface Soil</mat-placeholder>
      <mat-select formControlName="IrrSoil" multiple>
        <app-select-check-all [model]="mediaForm.get('IrrSoil')" [values]="irrSoilShortList">
        </app-select-check-all>
        <mat-option *ngFor="let option of irrigatedSurfaceSoil | async" [value]="option.key">
          {{option.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      (click)="openDialog('Surface Soil Not Irrigated')">
      <mat-placeholder>Surface Soil Not Irrigated</mat-placeholder>
      <mat-select formControlName="NotIrrSoil" multiple>
        <app-select-check-all [model]="mediaForm.get('NotIrrSoil')" [values]="notirrSoilShortList">
        </app-select-check-all>
        <mat-option *ngFor="let option of surfaceSoilNotIrr| async" [value]="option.key">
          {{option.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      (click)="openDialog('River Water')">
      <mat-placeholder>River Water</mat-placeholder>
      <mat-select formControlName="RiverWater" multiple>
        <app-select-check-all [model]="mediaForm.get('RiverWater')" [values]="riverWaterShortList">
        </app-select-check-all>
        <mat-option *ngFor="let option of riverWater| async" [value]="option.key">
          {{option.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      (click)="openDialog('Seep Water')">
      <mat-placeholder>Seep Water</mat-placeholder>
      <mat-select formControlName="SeepWater" multiple>
        <app-select-check-all [model]="mediaForm.get('SeepWater')" [values]="seepWaterShortList">
        </app-select-check-all>
        <mat-option *ngFor="let option of seepWater| async" [value]="option.key">
          {{option.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-card-actions>
      <ng-content></ng-content>
    </mat-card-actions>

  </form>

</mat-card>
