import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from '../../environments/environment';

// @Injectable({
//   providedIn: 'root'
// })
export class ScenarioService {


  constructor(private url: string, private http: HttpClient) {
  }

  getChoices(scenario ?: string) {
    scenario = scenario ? `${scenario}/` : '';
    return this.http.get<any[]>(`${environment.ctuir_api_url}${this.url}/${scenario}`);
  }
}
