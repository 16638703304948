import {Injectable} from '@angular/core';
import {loadModules} from 'esri-loader';
import {Observable, ReplaySubject, Subject, timer} from 'rxjs';
import {flatMap, map, takeUntil} from 'rxjs/operators';
import {nextTick} from "q";
import {tap} from 'rxjs/internal/operators/tap';
import {switchMap} from 'rxjs/internal/operators/switchMap';

// @Injectable({
//   providedIn: 'root'
// })
export class ArcGeoprocessorService {
  gp;
  esriConfig;
  ready = new ReplaySubject();
  url: string;
  jobId: Subject<string> = new Subject();

  constructor(url: string) {
    const vm = this;
    vm.url = url;
    loadModules(['esri/tasks/Geoprocessor', 'esri/config']).then(([Geoprocessor, _esriConfig]) => {
      vm.gp = new Geoprocessor(`${url}`);
      vm.esriConfig = _esriConfig;
      vm.ready.next();
    });
  }

  private initRequestInterceptor() {
    const vm = this;
    vm.esriConfig.request.interceptors.push({
      urls: vm.url,
      after: response => {
        vm.jobId.next(response.data.jobId);
        vm.jobId.complete();
      }
    })
  }

  public submit(params) {
    const vm = this;
    return new Observable<any>(obs => {
      vm.ready.subscribe(() => {
        vm.initRequestInterceptor();
        vm.gp.submitJob(params).then(function (e) {
          if (e.jobStatus === 'job-failed') {
            // todo: raise error
            obs.error();
          } else {
            // vm.gp.getResultData(e.jobId, 'heat_map', function (result) {
            obs.next(e.jobId);
            obs.complete();
            // });
          }
        })
      });
    });
  }

  public checkStatus(jobId: string) {
    const vm = this;
    let failed = ["job-failed", "job-cancelled", 'job-cancelling', 'job-deleted', 'job-deleting', 'job-timed-out'];
    let execute = ["job-executing", "job-failed", "job-new", "job-submitted", "job-waiting"];
    return new Observable(obs => {
      let sub = timer(0, 5000).pipe(
        switchMap(() => vm.ready),
        tap(() => {
          vm.gp.checkJobStatus(jobId).then(function (e) {
            if (failed.indexOf(e.jobStatus) > -1) {
              obs.error();
              sub.unsubscribe();
            } else if (e.jobStatus === 'job-succeeded') {
              obs.next(jobId);
              obs.complete();
              sub.unsubscribe();
            }
          }).catch(function (error) {
            obs.error();
            sub.unsubscribe();
          });
        })
      ).subscribe();
    });
  }
}
