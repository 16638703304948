import {Component, OnInit, Inject} from '@angular/core';
import {SelectionModel} from "@angular/cdk/collections";
import {MatTableDataSource} from "@angular/material";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from '@angular/material/dialog';
import {PopUpDialogComponent} from "../pop-up-dialog/pop-up-dialog.component";


@Component({
  selector: 'app-age-selector',
  templateUrl: './age-selector.component.html',
  styleUrls: ['./age-selector.component.css']
})

export class AgeSelectorComponent {
  ageForm: FormGroup;
  ageData: any[];

  returned: string;
  name: string;
  message: string;

  constructor(private _formBuilder: FormBuilder, public dialog: MatDialog) {

  }

  ngOnInit() {
    this.ageForm = this._formBuilder.group({
      Age: ['Lifetime', Validators.required]
    });
    this.ageData = [
      {value: 'Adult', viewValue: 'Adult'},
      {value: 'Child', viewValue: 'Child'},
      {value: 'Lifetime', viewValue: 'Lifetime'},
    ]
  }

  openDialog(): void {
    this.name = this.ageForm.value.Age
    this.message = "WARNING - Omitting the child or adult years will reduce the calculated risk! Lifetime risk depends on having both parts."
    if (this.name != 'Lifetime') {
      const dialogRef = this.dialog.open(PopUpDialogComponent, {
        width: '250px',
        data: {name: this.name, message: this.message, returned: this.returned}
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed: ' + result);
        this.returned = result;
      });
    }
  }

}

