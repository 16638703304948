<mat-toolbar class="header"  style="justify-content: space-between">

  <div routerLink="" style="cursor: pointer;display: flex; align-items: center;">CTUIR Risk Calculator Web Application v{{version.version}}</div>

  <div>
      <button mat-button [matMenuTriggerFor]="menu">{{ loginService.display_name }}</button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </div>
</mat-toolbar>
<div class="main-content" style="display: flex; flex: 1">
  <router-outlet></router-outlet>
</div>
