import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {SatPopoverModule} from '@ncstate/sat-popover';
import {
  MatButtonModule,
  MatCardModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatSnackBarModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatSelectModule,
  MatProgressBarModule,
  MatDialogModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatTableDataSource, MatFormFieldControl, MatStepperModule, MatMenu, MatMenuModule, MatExpansionModule
} from '@angular/material';
import {HomePageComponent} from './home-page/home-page.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ScenarioSelectorComponent} from './scenario-selector/scenario-selector.component';
import {AgeSelectorComponent} from './age-selector/age-selector.component';
import {MediaPathwaysComponent} from './media-pathways/media-pathways.component';
import {EnvironmentPathwaysComponent} from './environment-pathways/environment-pathways.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SelectorStepperComponent} from './selector-stepper/selector-stepper.component';
import {MapViewComponent} from './map-view/map-view.component';
import {ExistingDataComponent} from './existing-data/existing-data.component';
import {LoadingService} from './services/loading.service';
// import {ExposureMediaPathwaysService} from "./services/exposure-media-pathways.service";
import {UrfTableComponent} from './urf-table/urf-table.component';
import {ResultsComponent} from './results/results.component';
import {SelectCheckAllComponent} from './select-check-all/select-check-all.component';
import {PopUpDialogComponent} from './pop-up-dialog/pop-up-dialog.component';
import {HttpRequestInterceptor} from './http-request.interceptor';
import {LoginComponent} from './login/login.component';
import {LoginService} from './login.service';


@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    ScenarioSelectorComponent,
    AgeSelectorComponent,
    MediaPathwaysComponent,
    EnvironmentPathwaysComponent,
    SelectorStepperComponent,
    MapViewComponent,
    ExistingDataComponent,
    UrfTableComponent,
    ResultsComponent,
    SelectCheckAllComponent,
    PopUpDialogComponent,
    LoginComponent
  ],
  entryComponents: [PopUpDialogComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatToolbarModule,
    MatIconModule,
    AppRoutingModule,
    MatProgressBarModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatTabsModule,
    MatTableModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatMenuModule,
    SatPopoverModule,
    MatExpansionModule
  ],
  exports: [],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpRequestInterceptor,
    multi: true
  },
    LoginService,
    LoadingService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
