import {Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ScenarioSelectorComponent} from '../scenario-selector/scenario-selector.component';
import {AgeSelectorComponent} from '../age-selector/age-selector.component';
import {MediaPathwaysComponent} from '../media-pathways/media-pathways.component';
import {EnvironmentPathwaysComponent} from '../environment-pathways/environment-pathways.component';
import {ExistingDataComponent} from '../existing-data/existing-data.component';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {HttpClient} from '@angular/common/http';
// import {ConfigService} from '../services/config.service';
import {LoadingService} from '../services/loading.service';
import {URFresultsService} from '../services/urfresults.service';
import {Router} from '@angular/router';
import {filter, finalize, flatMap, map, take} from 'rxjs/operators';
import {interval} from 'rxjs';
import {environment} from '../../environments/environment';
import {tap} from 'rxjs/internal/operators';

@Component({
  selector: 'app-selector-stepper',
  templateUrl: './selector-stepper.component.html',
  styleUrls: ['./selector-stepper.component.css']
})
export class SelectorStepperComponent {
  @Input() scenario: string;
  @Input() stepChange: EventEmitter<StepperSelectionEvent>;
  //
  isLinear = false;
  calculating = false;
  @ViewChild(ScenarioSelectorComponent, {static: true}) scenarioSelectorComponent: ScenarioSelectorComponent;
  @ViewChild(AgeSelectorComponent, {static: true}) ageSelectorComponent: AgeSelectorComponent;
  @ViewChild(MediaPathwaysComponent, {static: true}) mediaPathwaysComponent: MediaPathwaysComponent;
  @ViewChild(EnvironmentPathwaysComponent, {static: true}) environmentalPathwaysComponent: EnvironmentPathwaysComponent;
  @ViewChild(ExistingDataComponent, {static: true}) existingDataComponent: ExistingDataComponent;

  get scenarioForm() {
    return this.scenarioSelectorComponent ? this.scenarioSelectorComponent.scenarioForm : null;
  }

  get ageForm() {
    return this.ageSelectorComponent ? this.ageSelectorComponent.ageForm : null;
  }

  get mediaForm() {
    return this.mediaPathwaysComponent ? this.mediaPathwaysComponent.mediaForm : null;
  }


  constructor(private _formBuilder: FormBuilder, private http: HttpClient, public loadingService: LoadingService, private router: Router) {
  }

  submitSelections() {
    this.calculating = true;
    // this.loadingService.show()
    this.http.post<any>(`${environment.ctuir_api_url}chem/${this.scenarioForm.value.Scenario}/${this.ageForm.value.Age}/`, this.mediaForm.value)
      .pipe(
        flatMap(response => interval(5000).pipe(
            flatMap(() => this.http.get(`${environment.ctuir_api_url}gettaskstatus/${response.task}/`)), //pass back two query parameters. response.chemicaltask
            filter(x => x === 'SUCCESS' ),
            take(1),
            map(() => this.router.navigate(['/map'], {queryParams: {input_data: response.final_result_id}})))),
        finalize(() => this.calculating = false)
      ).subscribe();
  };
}

