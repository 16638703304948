import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from './loading.service';
// import {load} from '@angular/core/src/render3/instructions';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BaseService{

  constructor(http: HttpClient, loadingService: LoadingService) {
    super('http://127.0.0.1:8000/api/', http, loadingService)
  }
}
