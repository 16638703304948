<mat-vertical-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="scenarioForm">
    <ng-template matStepLabel>Scenario <span class="mat-headline">{{scenarioForm.value.Scenario}}</span></ng-template>
    <app-scenario-selector></app-scenario-selector>
  </mat-step>
  <mat-step [stepControl]="ageForm">
    <ng-template matStepLabel>Age <span class="mat-headline">{{ageForm.value.Age}}</span></ng-template>
    <app-age-selector></app-age-selector>
  </mat-step>
  <mat-step [stepControl]="mediaForm">
    <ng-template matStepLabel>Exposure Media and Pathways</ng-template>

    <app-media-pathways [scenario]="scenarioForm.get('Scenario').valueChanges | async"
                        [stepChange]="stepper.selectionChange">

      <button *ngIf="!calculating" mat-raised-button (click)="submitSelections()"
              [disabled]='mediaPathwaysComponent.choicesFulfilled < 5 '>Submit
        <mat-progress-spinner *ngIf='mediaPathwaysComponent.choicesFulfilled < 5 ' [diameter]="36" [mode]="'indeterminate'"></mat-progress-spinner>
      </button>

      <button *ngIf="calculating" mat-raised-button>
        <mat-progress-spinner [diameter]="36" [mode]="'indeterminate'"></mat-progress-spinner>
      </button>
    </app-media-pathways>
  </mat-step>
</mat-vertical-stepper>
