/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-check-all.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/material/checkbox";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./select-check-all.component";
var styles_SelectCheckAllComponent = [i0.styles];
var RenderType_SelectCheckAllComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SelectCheckAllComponent, data: {} });
export { RenderType_SelectCheckAllComponent as RenderType_SelectCheckAllComponent };
export function View_SelectCheckAllComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-checkbox", [["class", "mat-option mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.toggleSelection($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MatCheckbox]), i1.ɵdid(2, 8568832, null, 0, i4.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i5.FocusMonitor, i1.NgZone, [8, null], [2, i4.MAT_CHECKBOX_CLICK_ACTION], [2, i6.ANIMATION_MODULE_TYPE]], { checked: [0, "checked"], indeterminate: [1, "indeterminate"] }, { change: "change" }), (_l()(), i1.ɵted(3, 0, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.isChecked(); var currVal_8 = _co.isIndeterminate(); _ck(_v, 2, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 2).indeterminate; var currVal_3 = i1.ɵnov(_v, 2).checked; var currVal_4 = i1.ɵnov(_v, 2).disabled; var currVal_5 = (i1.ɵnov(_v, 2).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = _co.text; _ck(_v, 3, 0, currVal_9); }); }
export function View_SelectCheckAllComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-select-check-all", [], null, null, null, View_SelectCheckAllComponent_0, RenderType_SelectCheckAllComponent)), i1.ɵdid(1, 49152, null, 0, i7.SelectCheckAllComponent, [], null, null)], null, null); }
var SelectCheckAllComponentNgFactory = i1.ɵccf("app-select-check-all", i7.SelectCheckAllComponent, View_SelectCheckAllComponent_Host_0, { model: "model", values: "values", text: "text" }, {}, []);
export { SelectCheckAllComponentNgFactory as SelectCheckAllComponentNgFactory };
