// someday maybe reintegrate git-describe
// https://medium.com/@amcdnl/version-stamping-your-app-with-the-angular-cli-d563284bb94d
export const VERSION = {
    "dirty": true,
    "raw": "93a4643-dirty",
    "hash": "93a4643",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "93a4643-dirty",
    "semverString": null,
    "version": "1.0.2"
};

