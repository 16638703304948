import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';


export class ExposureMediaPathwaysService {

  constructor(public url: string, public http: HttpClient) {
  }

  getChoices(scenario ?: string)  {
    scenario = scenario ? `${scenario}/` : '';
    return this.http.get<any[]>(`${environment.ctuir_api_url}${this.url}/${scenario}`);
  }
}
