import {Injectable} from '@angular/core';
import {loadModules} from 'esri-loader';
import {catchError, finalize, switchMap, map} from 'rxjs/operators';

import {getInitializer} from 'codelyzer/util/astQuery';
import {Observable, BehaviorSubject, ReplaySubject, empty} from "rxjs";
import {NgStyle} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  mapObs: ReplaySubject<any> = new ReplaySubject<any>();
  basemaps: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  map;

  constructor() {
  }

  init(elementId) {
    let map, view, vm = this;
    const usaTopo = {
      baseMapLayers: [{url: 'https://services.arcgisonline.com/ArcGIS/rest/services/USA_Topo_Maps/MapServer'}],
      thumbnailUrl: 'http://www.arcgis.com/sharing/rest/content/items/931d892ac7a843d7ba29d085e0433465/info/thumbnail/usa_topo.jpg',
      title: 'USA Topo'
    };
    // vm.mapObs = new ReplaySubject<any>();
    loadModules(['esri/Map', 'esri/views/MapView', 'esri/widgets/LayerList', 'esri/widgets/Legend'])
      .then(([Map, MapView, LayerList, Legend]) => {
        // vm.basemaps.next(
        //   [basemaps.streets,
        //     basemaps.satellite,
        //     basemaps.terrain,
        //     basemaps.topo,
        //     basemaps.oceans,
        //     basemaps.gray,
        //     usaTopo]);

        vm.map = new Map({
          // center: [-119.4890, 46.5507],
          // zoom: 11,
          basemap: 'topo',
          // autoResize: true,
          // slider: true
        });
        view = new MapView({
          map: vm.map,
          container: elementId,
          center: [-119.482, 46.645],
          zoom: 13,
        });

        var legend = new Legend({
          view: view
        });
        view.ui.add(legend, "bottom-right");

        var layerList = new LayerList({
          view: view,

          listItemCreatedFunction: function (event) {

            // The event object contains properties of the
            // layer in the LayerList widget.

            var item = event.item;
             if ((item.title === "RiskCalcGPService") || (item.title === "500ft² Grid")) {
               item.visible = true;
             }
             else {
               item.visible = false;
             }

          }
        });

        view.ui.add(layerList, {
          position: "top-left"
        });
        view.when(function () {
          vm.mapObs.next([vm.map, view]);
          vm.mapObs.complete();
        });
      });
    return this.mapObs;
  }

  addLayer(layer) {
    const vm = this;
    this.mapObs.subscribe(() => {
      vm.map.add(layer);
    });
  }

  center(geometry) {
    this.mapObs.subscribe(map => {
      setTimeout(() => {
        if (geometry.type === 'point') map.centerAt(geometry);
        else if (geometry.type === 'polygon') map.setExtent(geometry.getExtent(), true);

        // map.setZoom(map.getZoom() - 1);
      }, 500);
    });
  }

  zoomIn() {
    this.mapObs.subscribe(map => map.setZoom(map.getZoom() + 1));
  }

  zoomOut() {
    this.mapObs.subscribe(map => map.setZoom(map.getZoom() - 1));
  }

  addGraphic(graphic) {
    this.mapObs.subscribe(map => map.graphics.add(graphic));
  }

  redrawGraphics() {
    this.mapObs.subscribe(map => map.graphics.redraw());
  }

  clearGraphics() {
    this.mapObs.subscribe(map => map.graphics.clear());
  }

  switchBasemap(basemap) {
    this.mapObs.subscribe(map => map.setBasemap(basemap));
  }

}
