<!--<div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start">-->
<!--    <mat-card>-->
<!--      <mat-card-title>-->
<!--        Overview-->
<!--      </mat-card-title>-->
<!--      <mat-card-content>The Confederated Tribes of the Umatilla Indian Reservation (CTUIR) solicited support from a-->
<!--contractor to help define and develop a tribally-relevant human health risk assessment tool to-->
<!--facilitate communication of environmental sampling results to the people of the CTUIR. Unit Risk-->
<!--Factors (URFs) are currently used by the CTUIR to estimate health risk and are calculated in-->
<!--complex spreadsheets developed in Microsoft® (MS) Excel by Dr. Paul Rittmann. The new risk-->
<!--assessment tool will integrate Dr. Rittmann’s model with Geographic Information Systems (GIS) to-->
<!--create a user-friendly online visual tool.-->
<!--The purpose of this System Design Document (SDD) is to present the design and architecture of the-->
<!--new CTUIR risk tool. Certain design and architecture details will be worked out as development of-->
<!--the tool progresses due to the use of the SCRUM software development process (described later in-->
<!--this document). Consequently, the first draft of this SDD does not present specific details on-->
<!--database designs and user interfaces; more details will be added to a future revision of this SDD-->
<!--after further development of the tool has occurred</mat-card-content>-->
<!--  </mat-card>-->
<!--</div>-->
<div style="display: flex; justify-content: space-around; flex: 1; flex-direction: column; align-items: center">
  <mat-card>
    <mat-card-header>
      <mat-card-title><b>Welcome to the CTUIR Risk Calculator</b>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>This software application was created out of a need for a human health risk assessment tool specific to the
        Confederated Tribes of the Umatilla Indian Reservation (CTUIR) tribal exposure scenarios at the USDOE Hanford
        Site. Unit Risk Factors (URFs) calculated from exposure scenario parameters are combined with Exposure Point
        Concentrations (EPCs) modeled from environmental monitoring data. Resulting values may be used to estimate
        health risks to CTUIR Tribal Members, based on tribally-relevant lifetime exposure scenarios and pathways.</p>
      <p>This application is intended to be used and interpreted by CTUIR personnel familiar with URFs and the environmental data used to estimate
        EPCs. A description of this risk assessment tool, the exposure scenarios, modeling assumptions, and system
        design information is included in the most current version of the Unit Risk Factor Calculator,
        System Design Document</p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button [routerLink]="['select']" color="primary">
        Click to begin
      </button>
    </mat-card-actions>
  </mat-card>
</div>
