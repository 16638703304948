<div class="login-container">
  <div>
    <mat-card style="display: flex; flex-direction: column;">
      <mat-form-field>
        <input matInput placeholder="Username" [(ngModel)]="username" (keyup.enter)="loginWithCredentials()">
      </mat-form-field>
      <br/>
      <mat-form-field>
        <input matInput type="password" placeholder="Password" [(ngModel)]="password" (keyup.enter)="loginWithCredentials()">
      </mat-form-field>
      <br/>
      <button mat-raised-button (click)="loginWithCredentials()">Login</button>
      <div style="color: red; font-size: 15px; max-width: 200px;">{{ login_error }}</div>
      <br/>
    </mat-card>
  </div>
</div>
