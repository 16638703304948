import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup,} from "@angular/forms";
import {ExposureMediaPathwaysService} from "../services/exposure-media-pathways.service";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs/index";
import {map} from "rxjs/internal/operators";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {MatDialog} from "@angular/material";
import {PopUpDialogComponent} from "../pop-up-dialog/pop-up-dialog.component";
import enumerate = Reflect.enumerate;

@Component({
  selector: 'app-media-pathways',
  templateUrl: './media-pathways.component.html',
  styleUrls: ['./media-pathways.component.css']
})
export class MediaPathwaysComponent implements OnInit {
  @Input() scenario: string;
  @Input() stepChange: EventEmitter<StepperSelectionEvent>;

  choicesFulfilled: any;
  returned: string;
  name: string;
  message: string;

  mediaForm: FormGroup;

  aquiferWater: Observable<any>;
  aquiferWaterDefaults: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  aquiferpathwayservice: ExposureMediaPathwaysService;
  aquiferShortList: any;

  irrigatedSurfaceSoil: Observable<any>;
  irrsoilservice: ExposureMediaPathwaysService;
  irrSoilDefaults: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  irrSoilShortList: any;

  surfaceSoilNotIrr: Observable<any>;
  notirrsoilservice: ExposureMediaPathwaysService;
  notirrSoilDefaults: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  notirrSoilShortList: any;

  riverWater: Observable<any>;
  riverwaterservice: ExposureMediaPathwaysService;
  riverWaterDefaults: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  riverWaterShortList: any;

  seepWater: Observable<any>;
  seepwaterservice: ExposureMediaPathwaysService;
  seepWaterDefaults: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  seepWaterShortList: any;


  constructor(private _formBuilder: FormBuilder, private http: HttpClient, public dialog: MatDialog) {
    this.aquiferpathwayservice = new ExposureMediaPathwaysService('aquiferchoices', this.http);
    this.irrsoilservice = new ExposureMediaPathwaysService('irrsoilchoices', this.http);
    this.notirrsoilservice = new ExposureMediaPathwaysService('notirrsoilchoices', this.http);
    this.riverwaterservice = new ExposureMediaPathwaysService('riverwaterchoices', this.http);
    this.seepwaterservice = new ExposureMediaPathwaysService('seepwaterchoices', this.http);

  }

  ngOnInit() {
    this.stepChange.subscribe(event => {
        if (event.selectedIndex === 2) {
          this.choicesFulfilled = 0; // yeah, I did that. COF
          this.aquiferWater = this.aquiferpathwayservice.getChoices(this.scenario).pipe(map(results => {
            this.mediaForm.patchValue({'Aquifer': results.map(result => result.key)});
            this.aquiferShortList = this.getShortList(results);
            this.choicesFulfilled ++;
            return results;
          })),
            this.irrigatedSurfaceSoil = this.irrsoilservice.getChoices(this.scenario).pipe(map(results => {
              this.mediaForm.patchValue({'IrrSoil': results.map(result => result.key)});
              this.irrSoilShortList = this.getShortList(results);
              this.choicesFulfilled ++;
              return results;
            })),
            this.surfaceSoilNotIrr = this.notirrsoilservice.getChoices(this.scenario).pipe(map(results => {
              this.mediaForm.patchValue({'NotIrrSoil': results.map(result => result.key)});
              this.notirrSoilShortList = this.getShortList(results);
              this.choicesFulfilled ++;
              return results;
            })),
            this.riverWater = this.riverwaterservice.getChoices(this.scenario).pipe(map(results => {
              this.mediaForm.patchValue({'RiverWater': results.map(result => result.key)});
              this.riverWaterShortList = this.getShortList(results);
              this.choicesFulfilled ++;
              return results;
            })),
            this.seepWater = this.seepwaterservice.getChoices(this.scenario).pipe(map(results => {
              this.mediaForm.patchValue({'SeepWater': results.map(result => result.key)});
              this.seepWaterShortList = this.getShortList(results);
              this.choicesFulfilled ++;
              return results;
            }));
        }
      }
    );

    this.mediaForm = this._formBuilder.group({
      Aquifer: [''],
      IrrSoil: [''],
      NotIrrSoil: [''],
      RiverWater: [''],
      SeepWater: [''],
    });
  }

  private getShortList(results) {
    let myList = [];
    results.forEach(function (element) {
      myList.push(element.key);
    });
    return myList;
  }

  openDialog(name): void {
    this.name = name;
    this.message = 'WARNING – Deselecting any default options, parameters or pathways will change the exposure scenario! This action may result in unrealistic risk outcomes and is not advised. Consult application management team personnel or a qualified risk assessment professional for more information.';

    const dialogRef = this.dialog.open(PopUpDialogComponent, {
      width: '295px',
      data: {name: this.name, message: this.message, returned: this.returned}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed: ' + result);
      this.returned = result;
    });

  }

}
